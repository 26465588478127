:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: .75rem;
}

.cardSignin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.cardSignin .cardTitle {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.cardSignin .cardBody {
  padding: 2rem;
}

.formSignin {
  width: 100%;
}

.formSignin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

 .formLabelGroup {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 1rem;
}


.formLabelGroup>input,
.formLabelGroup>label {
  padding: var(--input-padding-y) var(--input-padding-x);
}


/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .formLabelGroup>label {
    display: none;
  }
  .formLabelGroup input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .formLabelGroup>label {
    display: none;
  }
  .formLabelGroup input:-ms-input-placeholder {
    color: #777;
  }
}